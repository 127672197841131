<template>
  <div>
    <before-login
      v-if="!isLogin"
      :departmentStatus="departmentStatus"
    ></before-login>
    <after-login v-else :departmentStatus="departmentStatus"></after-login>

    <!-- textarea send message -->
    <panel-send-message v-if="isLogin"></panel-send-message>
  </div>
</template>

<script>
import AfterLogin from "@/components/customer/body/AfterLogin";
import BeforeLogin from "@/components/customer/body/BeforeLogin";
import PanelSendMessage from "@/components/customer/sendMessage";
import { db } from "@/common/firebase/init";
import { waktu, request, alert } from "@/common/mixins/mix_helper";
import { departemenStatus } from "@/common/config";

export default {
  name: "BodyBase",
  mixins: [waktu, request, alert],
  props: {
    isLogin: {
      required: true,
      type: Boolean,
    },
  },
  components: {
    AfterLogin,
    BeforeLogin,
    PanelSendMessage,
  },
  data() {
    return {
      departmentStatus: departemenStatus,
    };
  },
  created() {
    /**
     * --------------------------------------------------------------
     * listen firebase department status on/off
     * --------------------------------------------------------------
     */
    db.ref("others/department-status").on("value", (status) => {
      this.departmentStatus = status.val();
    });
  },
};
</script>
