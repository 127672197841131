<template>
  <div>
    <BotPartBubble v-if="checkTipeMsg(message.tipe)" :message="message" />
    <!--    <div v-if="checkTipeMsg(message.tipe)">-->
    <!--      <div class="text-muted alert-message">-->
    <!--        <img-->
    <!--          src="img/svg/volume-up-solid.svg"-->
    <!--          alt="alert"-->
    <!--          style="width: 14px"-->
    <!--        />-->

    <!--        &nbsp;-->
    <!--        <span v-if="message.tipe === 'agent-close'">{{-->
    <!--          mixEpochToFullDate(message.time)-->
    <!--        }}</span>-->
    <!--        <span v-else>{{ mixEpochToHours(message.time) }}</span>-->

    <!--        &nbsp;-->
    <!--        <i v-html="message.message"></i>-->
    <!--      </div>-->
    <!--    </div>-->

    <!-- choose departemen -->
    <div
      v-if="
        message.tipe === 'chooseDepartemen' &&
        getConfigJs.pilihDepartemen.uuid === parseInt(message.time)
      "
    >
      <div class="float-left text-center">
        <img
          class="mt-2"
          :src="botImage"
          :alt="message.name"
          :title="message.name"
          style="height: 30px; border-radius: 50px 50px 50px 50px"
        />
        <div>
          <small>{{ message.name }}</small>
        </div>
      </div>
      <div class="rw-chat-text">
        <template v-if="allDeptIsOff">
          <div class="my-1 text-center">
            Mohon maaf, Saat ini staff kami sedang offline.<br />
            Silakan menghubungi kami melalui email atau melalui sistem ticketing
            pada menu 'Trouble Ticket' di Client Area. Silakan klik tombol
            berikut untuk menuju Client Area.
          </div>
          <a
            href="https://new.clientzone.rumahweb.com/support/ticket"
            target="_blank"
            class="btn btn-info btn-block btn-sm btn-rw-circle"
            >Open Ticket</a
          >
        </template>
        <template v-else>
          <div class="mb-1">{{ message.message }}:</div>
          <template v-for="(divisi, key) in departmentReady">
            <a
              href="javascript:void(0)"
              :key="key"
              class="btn btn-info btn-sm mr-1 btn-rw-circle"
              :class="divisi.status !== 'Online' ? 'disabled' : ''"
              @click="pilihTujuanDivisi(divisi)"
              >{{ divisi.name }}
            </a>
          </template>
        </template>
      </div>
      <div style="clear: both"></div>
    </div>

    <!-- rating staff -->
    <div
      v-if="
        message.tipe === 'agent-close' &&
        getConfigJs.rating.uuid === message.uuid
      "
      :id="message.time"
    >
      <div class="float-left text-center">
        <img
          class="mt-2"
          :src="botImage"
          :alt="message.name"
          :title="message.name"
          style="height: 30px; border-radius: 50px 50px 50px 50px"
        />
        <div>
          <small>{{ message.name }}</small>
        </div>
      </div>
      <div class="rw-chat-text py-2">
        <h6 class="text-center font-weight-bold">
          Seberapa puas Anda dengan pelayanan sesi chat ini?
        </h6>
        <div class="rating d-flex justify-content-center">
          <label v-for="(value, faceName) in ratingFace" :key="faceName">
            <input type="radio" v-model="form.ratingValue" :value="value" />
            <img
              :src="`/img/svg/emot-rate/${faceName}.svg`"
              :alt="faceName"
              class="emot-fill"
            />
            <img
              :src="`/img/svg/emot-rate/${faceName}-line.svg`"
              :alt="faceName"
              class="emot-line"
            />
          </label>
        </div>

        <template v-if="tellMeMoreShow">
          <div class="small pl-1">Beri tahu kami, apa yang kurang :</div>
          <div
            class="tell-me-more btn-group-toggle btn-group-sm my-1 d-flex align-items-center flex-wrap"
          >
            <label
              class="btn"
              :class="tellMeChecked(i) ? 'btn-dark' : 'btn-outline-dark'"
              v-for="i in tellMeMore[tellMeMoreStar]"
              :key="i"
            >
              <input type="checkbox" :value="i" v-model="form.tellMeMore" />
              <div v-html="i"></div>
            </label>
          </div>
        </template>

        <textarea
          @keyup.enter="sendRating()"
          class="form-control"
          v-model="form.ratingMessage"
          placeholder="Tinggalkan penilaian dan ulasanmu disini"
        ></textarea>
        <br />
        <button class="btn btn-info btn-block btn-sm" @click="sendRating">
          Kirim
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/customer/rw-rating.scss";
import { waktu, request, alert } from "@/common/mixins/mix_helper";
import { loader } from "@/common/mixins/mix_loader";
import { clientCookies } from "@/common/mixins/mix_cookies";
import { mapGetters } from "vuex";
import AppConfig from "@/common/config/app.config.json";
import BotPartBubble from "@/components/customer/body/conversation/BotPartBubble.vue";

export default {
  name: "BotPart",
  mixins: [waktu, clientCookies, loader, request, alert],
  components: { BotPartBubble },
  props: {
    message: {
      required: true,
    },
    departmentReady: {
      required: true,
    },
  },
  data() {
    return {
      botImage: "img/rw/bot.png",
      form: {
        ratingValue: "",
        ratingMessage: "",
        tellMeMore: [],
      },
      ratingFace: {
        tear: "1",
        sad: "2",
        meh: "3",
        smile: "4",
        laugh: "5",
      },
      tellMeMore: {
        satu: [
          "Tidak ramah",
          "Tidak memberi solusi",
          "Bahasanya belibet",
          "Tidak membantu",
        ],
        dua: [
          "Kurang ramah",
          "Kurang memberi solusi",
          "Kurang pengetahuan",
          "Kurang membantu",
        ],
        global: [
          "Tidak membantu",
          "Masalah belum selesai",
          "Tidak mudah dipahami",
          "Respon Lambat",
          "Tidak Ramah",
        ],
      },
    };
  },
  methods: {
    /**
     * --------------------------------------------------------------
     * memilih departement untuk melanjutkan staff
     * nama akan dikirim / $emit ke parent untuk diproses
     * jika terjadi kegagalan ketika request oepenthread maka
     * cache akan dihapus dibersihkan dan mulai baru
     * --------------------------------------------------------------
     */
    pilihTujuanDivisi: function (divisi) {
      this.loading();
      let cookiesCurrent = this.cookiesGet();
      if (cookiesCurrent) {
        /**
         * --------------------------------------------------
         * supaya ketika proses request dan diinterup (ex refresh) maka
         * tombol pilih divisi tetep hilang tidak akan tampil lagi.
         * asumsi jika error openThread maka register dr awal jika tidak maka tombol pilih
         * divisi akan muncul lagi ketika client kirim pesan
         * --------------------------------------------------
         */
        let x = { pilihDepartemen: { uuid: null } };
        this.cookiesAdd(x);
        this.$store.dispatch("ConfigJs", x);

        let alertChat = () => {
          this.$swal({
            title: "Perhatian",
            html: "Anda sudah memiliki percakapan Aktif. Klik tombol dibawah untuk melanjutkan chat Anda",
            icon: "info",
            allowOutsideClick: false,
            allowEnterKey: false,
            confirmButtonText: "Lanjutkan",
          });
        };

        /**
         * --------------------------------------------------
         * cannot send reopen when null
         * --------------------------------------------------
         */
        if (cookiesCurrent.pilihDepartemen.uuid === null) {
          this.$store.dispatch("sendLogAPI", {
            actor: "client",
            message: "Cookies dept is null ketika reopen thread",
            cookies: cookiesCurrent,
            sendTelegram: true,
          });
          // this.trashAdd("pilihDept", "cookies null", cookiesCurrent);
          alertChat();
          this.loading(false);
          return;
        }

        // this.trashAdd("pilihDept", "pilih departemen");

        this.API.post(this.URL.thread.openThread, {
          deptid: parseInt(divisi.id),
        })
          .then(({ data }) => {
            if (typeof data.code !== "undefined") {
              // handle jika ada kemungkinan muncul button divisi > 1
              if (data.code === 201) {
                alertChat();
              }

              // disable textarea when code 200
              if (data.code === 200) {
                let isFallback = window.sessionStorage.getItem(
                  AppConfig.rwStorage.customer.fallback
                );
                if (isFallback === null || isFallback !== "1") {
                  this.$store.dispatch("ToggleTextArea", {
                    disable: true,
                  });
                }
                // append subthreadid to cookies keperluan export chat if fail
                this.cookiesAdd({ subid: data.data.subid });
              }
            }
          })
          .catch(() => {
            // this.trashAdd("pilihDept", "pilih departemen gagal");
            this.$swal({
              title: "Error",
              html: `Terjadi kegagalan dalam mengambil percakapan Anda yang terdahulu.<br>Sistem akan membuat thread baru.<br>Klik tombol <b>"Mulai"</b> untuk memulai thread baru.`,
              icon: "error",
              allowOutsideClick: false,
              allowEnterKey: false,
              confirmButtonText: "Mulai",
            }).then(({ isConfirmed }) => {
              if (isConfirmed) {
                this.handleLogoutFirebase();
              }
            });
          })
          .finally(() => {
            this.loading(false);
          });
      } else {
        this.handleLogoutFirebase();
      }
    },

    /**
     * --------------------------------------------------------------
     * kirim rating
     * --------------------------------------------------------------
     */
    sendRating: function () {
      if (this.form.ratingValue !== "") {
        let tellMeMore = this.form.tellMeMore;
        let text = "";
        Array.from(tellMeMore).forEach((a) => {
          text += `- ${a}\n`;
        });

        if (this.form.ratingMessage.trim() !== "") {
          if (["1", "2"].includes(this.form.ratingValue) && tellMeMore.length) {
            text += "catatan: ";
          }
          text += `${this.form.ratingMessage}`;
        }

        if (["1", "2"].includes(this.form.ratingValue) && !text.trim().length) {
          this.$swal(
            "Warning",
            "Mohon mengisi ulasan terlebih dahulu.",
            "warning"
          );
          return;
        }

        let roti = this.getCookies();
        if (
          roti &&
          typeof roti.customer !== "undefined" &&
          typeof roti.customer.id !== "undefined"
        ) {
          this.loading();
          this.API.post(this.URL.thread.rating, {
            star: this.form.ratingValue,
            message: text,
          })
            .then(() => {
              this.handleRatingSuccess();
            })
            .catch(() => {
              /**
               * --------------------------------------------------------------
               * walaupun gagal tetep dikasih success message sesuai doc
               * --------------------------------------------------------------
               */
              this.handleRatingSuccess();
            })
            .finally(() => {
              this.loading(false);
            });
        } else {
          this.handleLogoutFirebase();
        }
      } else {
        this.$swal("Warning", "Please choose rating first.", "warning");
      }
    },

    /**
     * --------------------------------------------------------------
     * helper for trigger if send rating
     * --------------------------------------------------------------
     */
    handleRatingSuccess() {
      let x = { rating: { uuid: null, endtime: null } };
      this.cookiesAdd(x); // js
      this.$store.dispatch("ConfigJs", x); // vuex

      this.form.ratingMessage = "";
      this.$swal("Success", "Rating has been sent.", "success");
    },

    getCookies: function () {
      let roti = this.cookiesGet();
      if (roti) {
        return roti;
      } else {
        this.cookies.remove();
        location.reload();
      }
      return false;
    },

    /**
     * --------------------------------------------------------------
     * check tipe message yang ditampilkan sebagai alert
     * --------------------------------------------------------------
     */
    checkTipeMsg: function (tipe) {
      let tipeAsAlert = [
        "alert",
        "agent-connect",
        "agent-join",
        "agent-leave",
        //"agent-close",
        "agent-off",
      ];
      return tipeAsAlert.includes(tipe);
    },

    /**
     * --------------------------------------------------------------
     * clear cookies & localstorage & logout firebase & reload
     * --------------------------------------------------------------
     */
    handleLogoutFirebase() {
      this.$store
        .dispatch("FbAuthSignOut")
        .then(() => {
          this.cookiesRemove(); // make sure remove cookies
          localStorage.removeItem(AppConfig.rwStorage.customer.token);
          localStorage.removeItem(AppConfig.rwStorage.agent.config);
        })
        .finally(() => {
          location.reload();
        });
    },

    tellMemoreReset() {
      this.form.tellMeMore = [];
    },

    tellMeChecked(word) {
      return this.form.tellMeMore.includes(word);
    },
  },
  computed: {
    allDeptIsOff: function () {
      let online = this.departmentReady.filter(
        (dept) => dept.status.toLowerCase() === "online"
      );
      return online.length === 0;
    },
    ...mapGetters(["getConfigJs"]),

    tellMeMoreShow() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.form.tellMeMore = [];
      let val = this.form.ratingValue;
      return ["1", "2"].includes(val);
    },

    tellMeMoreStar() {
      let star = this.form.ratingValue;
      switch (star) {
        case "1":
          star = "global";
          break;
        case "2":
          star = "global";
          break;
      }
      return star;
    },
  },
};
</script>

<style scoped lang="scss">
.alert-message {
  font-size: 0.8rem;
  margin-top: 0.9%;
  margin-bottom: 0.2%;
}
.btn-rw-circle {
  border-radius: 50px 50px 50px 50px;
}
textarea {
  &::placeholder {
    font-size: 0.9em;
  }
  &:focus {
    box-shadow: none;
  }
}

.tell-me-more {
  label {
    display: flex;
    align-items: center;
    height: 1.5em;
    margin: 0.2em;

    div {
      font-size: 0.7em;
    }
  }
}
</style>
