<template>
  <div class="container p-2">
    <!-- 1st time  -->
    <before-login v-if="!isLogin"></before-login>

    <!-- chat sedang berlangsung -->
    <after-login v-else></after-login>
  </div>
</template>

<script>
import beforeLogin from "@/components/customer/header/BeforeLogin";
import afterLogin from "@/components/customer/header/AfterLogin";

export default {
  name: "HeaderBase",
  components: {
    beforeLogin,
    afterLogin,
  },
  props: {
    isLogin: {
      required: true,
      type: Boolean,
    },
  },
};
</script>
