<template>
  <div class="d-inline-flex">
    <img src="img/rw/rw.svg" id="rwicon" class="m-2" alt="rumahweb" /> <br />
    <div class="tagline ml-2">
      <h5 class="font-weight-bold mt-2 mb-0">Rumahweb Indonesia</h5>
      <small>Painless Hosting Solution</small>
    </div>
  </div>
</template>

<script>
export default {
  name: "BeforeLogin",
};
</script>
