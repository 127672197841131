<template>
  <!-- textarea for chat-->
  <div class="d-inline-flex base-textarea">
    <div
      class="item"
      style="width: 17%; display: flex; justify-content: space-around"
    >
      <svg
        v-b-tooltip.hover
        title="Emoji"
        class="icon icon-emoji"
        @click="$store.dispatch('SetEmoji', { open: !getEmoji.open })"
      >
        <use xlink:href="#rw-emoji"></use>
      </svg>
      <span class="hiddenFileInput icon" v-b-tooltip.hover title="Attach File">
        <input type="file" @change="onChooseFile" ref="file" />
      </span>
    </div>
    <div class="item" style="width: 75%">
      <input
        @paste="onPaste"
        type="text"
        style="width: 100%"
        :placeholder="placeholder"
        :disabled="getTextArea.disable"
        v-model="message"
        @keyup.enter="sendMessage()"
        ref="message"
      />
    </div>
    <div class="btn-send" v-b-tooltip.hover title="Kirim">
      <svg class="icon" @click="sendMessage">
        <use xlink:href="#rw-paper-plane"></use>
      </svg>
    </div>
  </div>
</template>

<script>
// additional
import $ from "jquery";
import { loader } from "@/common/mixins/mix_loader";
import { mapGetters } from "vuex";
import { waktu, alert, util } from "@/common/mixins/mix_helper";
import { clientCookies } from "@/common/mixins/mix_cookies";
import AppConfig from "@/common/config/app.config.json";

export default {
  name: "sendMessage",
  mixins: [loader, clientCookies, alert, util],
  data() {
    return {
      message: "",
    };
  },
  methods: {
    /**
     * --------------------------------------------------------------
     * if copy paste image file in text area / input area
     * --------------------------------------------------------------
     */
    onPaste: function (e) {
      let files = e.clipboardData.files;
      if (files.length) {
        this.onChooseFile(files[0], true);
      }
    },

    /**
     * --------------------------------------------------------------
     * send message
     * --------------------------------------------------------------
     */
    sendMessage: function () {
      let message = this.generateTextMessage();

      if (this.message.trim() !== "") {
        // reset msg
        this.message = "";

        // realtime push / append
        this.$store.dispatch("MessagePush", message);
        setTimeout(() => {
          this.scrollToEndChat();
        }, 400);

        // push to db API
        this.$store
          .dispatch("customerSendMessage", message)
          .then(({ data }) => {
            // this.trashAdd("sendMsg", "berhasil");

            // make sure cookies reset to null value
            let x = { rating: { uuid: null, endtime: null } };
            this.cookiesAdd(x); // js
            this.$store.dispatch("ConfigJs", x); // vuex

            // if token has been expired
            if (typeof data.data.token !== "undefined") {
              localStorage.setItem(
                AppConfig.rwStorage.customer.token,
                data.data.token
              );
            }

            /**
             * --------------------------------------------------------------
             * check on success req API
             * --------------------------------------------------------------
             */
            if (typeof message.status !== "undefined") {
              message["status"] = "sent";
            }
            message["time"] = data.data.time ? data.data.time : message["time"];
            this.getAllMessage.filter((all, index) => {
              if (all.uuid === message.uuid) {
                this.$store.dispatch("MessageUpdate", {
                  index: index,
                  record: message,
                });
              }
            });

            /**
             * --------------------------------------------------------------
             * trigger get info jika flag nya false
             * --------------------------------------------------------------
             */
            if (!this.getAllVuexClient._getInfoAgent) {
              this.$store.dispatch("AgentInfo", message.threadid);
              this.$store.dispatch("setFlagInfoAgent", true);
            }
          })
          .catch(() => {
            // this.trashAdd("sendMsg", "gagal");
            if (typeof message.status !== "undefined") {
              message["status"] = "failed";
            }
            this.getAllMessage.filter((all, index) => {
              if (all.uuid === message.uuid) {
                this.$store.dispatch("MessageUpdate", {
                  index: index,
                  record: message,
                });
              }
            });
          });
      } else {
        this.$swal("error", "Message is required", "error");
      }
    },

    /**
     * --------------------------------------------------------------
     * generate text message regular not 1st message
     * --------------------------------------------------------------
     */
    generateTextMessage() {
      let uuid = () => {
        let str = Math.random().toString(36).substring(2);
        let epoch = Math.round(Date.now() / 1000);
        return str + "." + epoch;
      };

      let roti = this.cookiesGet();
      if (roti && roti.isLogin && typeof roti.threadid !== "undefined") {
        return {
          time: waktu.methods.mixEpoch(),
          tipe: "text",
          actor: "customer",
          message: this.escape(this.message),
          uuid: uuid(),
          threadid: roti.threadid,
          status: "pending",
        };
      } else {
        // paksa refresh page karna cookies gk ada
        location.reload();
      }
    },

    /**
     * --------------------------------------------------------------
     * ketika memilih file validasi ekstensi
     * --------------------------------------------------------------
     */
    onChooseFile: function (e, paste = false) {
      let attachment = paste ? e : e.target.files[0];

      let extFiles = AppConfig.ext_allowed.file;
      let extImages = AppConfig.ext_allowed.image;
      let allowedExt = [...extFiles, ...extImages];
      if (allowedExt.includes(attachment.type)) {
        let size = Math.round(attachment.size / 1024);
        if (size > 10240) {
          this.$swal({
            title: "Error",
            html: `Ukuran file maksimal 10MB!`,
            icon: "error",
          });
        } else {
          this.loading();
          this.$store
            .dispatch("customerSendMessageFile", {
              tipe: extImages.includes(attachment.type) ? "image" : "file",
              message: attachment,
            })
            .then(({ data }) => {
              if (typeof data.data.token !== "undefined") {
                localStorage.setItem(
                  AppConfig.rwStorage.customer.token,
                  data.data.token
                );
              }
              this.$swal("Success", "Attachment terkirim", "success");
            })
            .catch(() => {
              this.$swal(
                "Error",
                `sistem kami gagal mengirim file Anda, silahkan ulangi beberapa saat lagi, atau hubungi kami ke <a href="mailto:info@rumahweb.com">info@rumahweb.com</a> untuk melaporkan masalah ini.`,
                "error"
              );
            })
            .finally(() => {
              this.loading(false);
            });
        }
      } else {
        // alert when fail
        let ext = attachment.type.split("/");
        ext = ext[1].split("."); // get last ext
        this.$swal({
          title: "Error",
          html: `<b>${ext[ext.length - 1]}</b> tidak diizinkan! <br> \
	  			<small><b>PNG | JPEG | JPG | GIF | BMP | PDF</b></small>`,
          icon: "error",
        });
      }
    },

    /**
     * --------------------------------------------------------------
     * to scroll ed of element chat, must be use timeout
     * tidak dapat manipulasi dom langsung
     * --------------------------------------------------------------
     */
    scrollToEndChat: function () {
      let el = $("#chat");
      el.scrollTop(parseInt(el[0].scrollHeight));
    },
  },
  computed: {
    ...mapGetters([
      "getTextArea",
      "getEmoji",
      "getAllMessage",
      "getAllVuexClient",
    ]),
    placeholder: function () {
      return this.getTextArea.disable
        ? this.getTextArea.msgDisable
        : this.getTextArea.msgEnable;
    },
    listenSelectEmoji() {
      return {
        open: this.$store.state.customer._emoji.open,
        select: this.$store.state.customer._emoji.select,
        emot: this.$store.state.customer._emoji.emotIcon,
      };
    },
  },
  watch: {
    listenSelectEmoji(emoji) {
      if (emoji.select) {
        this.message = this.message + " " + emoji.emot;

        this.$refs.message.focus();
        this.$store.dispatch("SetEmoji", { select: false });
      }
    },
  },
};
</script>

<style scoped>
.base-textarea {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #f7f7f7;
  height: calc(100vh - 89vh);
  padding: 0 0.5rem;
}
.item {
  align-self: center;
}
.icon {
  fill: rgb(151, 151, 151);
  width: 24px;
  height: 32px;
  cursor: pointer;
}

input[type="text"] {
  font-weight: normal;
  background-color: #f7f7f7;
  border-radius: 6px;
  outline: none;
  overflow: auto;
  box-sizing: border-box;
  border: 0;
  flex: 1;
  font-size: 15px;
  font-family: Open Sans, sans-serif;
  line-height: 150%;
  max-height: 100%;
  resize: none;
  margin-left: 8px;
  align-self: center;
  color: rgb(102, 102, 102);
  height: 36px;
}

.btn-send {
  margin: auto;
}
.btn-send svg:hover {
  fill: #00aae5;
}
.icon-emoji:hover {
  fill: #f7c32b;
}
</style>
